import 'focus-within-polyfill';

import jQuery from './jquery-1.12.4.min.js';

window.jQuery=jQuery;

import './common';
import './validate';
import './home';
import './listnav';
import './content';
import './news';
import './myegov';
import './activity';
import './buttons';
import './mobile';
import './notifications';
