import jQuery from './jquery-1.12.4.min.js'; 

jQuery(function($) {
  
  $(".ac-months>li.open>.content,"+
    ".act-finance>li.open>.content").css("display", "block");
  $(".ac-months>li>.acl-head,"+
    ".act-finance>li>.acl-head").click(function(e) {
    $(this).parent().find(">.content").slideToggle(300);
    $(this).parent().toggleClass("open");
  });
  
});
