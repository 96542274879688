import jQuery from './jquery-1.12.4.min.js';
// import 'jquery-ui/ui/widgets/datepicker';
import './jquery-ui.js';

import Cookies from 'js-cookie';
window.jQuery=jQuery;


jQuery(function($) {
  $(".activity-list").each(function() {
    var list=$(this).find(">ul");
    var next=$(this).find("a.next");
    var prev=$(this).find("a.prev");
    prev.click(function(e) {
      e.preventDefault();
      var width=list.find(">li").innerWidth()+20;
      list.animate({scrollLeft: list.scrollLeft()-width}, 300);
    });
    next.click(function(e) {
      e.preventDefault();
      var width=list.find(">li").innerWidth()+20;
      list.animate({scrollLeft: list.scrollLeft()+width}, 300);
    });
  });
  
  
  $(".new-doc").click(function(e) {
    e.preventDefault();
    $("#add-popup.form-popup").addClass("visible");
    $("#add-popup.form-popup form")[0].reset();
    $('.datepicker .ui-datepicker-current-day').click();
  });
  $(".doc-list .ed").click(function(e) {
    e.preventDefault();
    var id=$(this).closest("li").attr("data-id");
    $("#edit-popup.form-popup").addClass("visible");
    $("#edit-popup.form-popup form")[0].reset();
    $('.datepicker .ui-datepicker-current-day').click();
  });
  $(".form-popup .close").click(function(e) {
    e.preventDefault();
    if ($(this).closest(".form-popup").find(".skip").length)
      Cookies.set("active-muni", "-1", {path: "/"});
    $(".form-popup").removeClass("visible");
  });
  $(".form-popup .skip").click(function(e) {
    e.preventDefault();
    $(".form-popup").removeClass("visible");
    Cookies.set("active-muni", "-1", {path: "/"});    
    location.reload();
  });
  
  $(".dl.with-popup").click(function(e) {
    e.preventDefault();
    var href=($(this).attr("href") || "").split("#")[1];
    $(".download-popup#"+href).addClass("visible");
  });
  $(".download-popup ul.tabs>li>a").click(function(e) {
    e.preventDefault();
    var index=$(this).closest("ul").find(">li>a").index(this);
    $(this).closest("ul").find(">li").removeClass("active");
    $(this).closest("li").addClass("active");
    $(this).closest("ul").next("ul.tab-content").find(">li").removeClass("active").eq(index).addClass("active");
  });
  
  $(".datepicker").each(function() {
    var dateInput=$(this).find("input");
    $(this).find(".datepicker-ui").datepicker({
      dateFormat: "dd.mm.yy.",
      dayNames: window.dayNames,
      dayNamesMin: window.dayNames.map((a)=>a.substring(0,2)),
      dayNamesShort: window.dayNames.map((a)=>a.substring(0,3)),
      monthNames: window.monthNames,
      monthNamesShort: window.monthNames.map((a)=>a.substring(0,3)),
      onSelect: function(newDate) {
        dateInput.val(newDate);
      },
    }).datepicker("setDate", dateInput.val() || "today");
  });
  
  
  $("a[data-confirm]").click(function(e) {
    if (!confirm($(this).attr("data-confirm")))
      e.preventDefault();
  });
});
