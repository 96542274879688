import jQuery from './jquery-1.12.4.min.js';
import zxcvbn from 'zxcvbn';


jQuery(function($) {
  
  var validate=function(reqs, value) {
    var valid=true;
    for(var req of reqs) {
      if (!validate[req.type])
        continue;
      if (!validate[req.type](value))
        valid=false;
      if (!valid)
        return {valid: false, msg: req.msg};
    }
    return {valid: valid};
  }
  validate.required=function(value) {
    if (value===null || value===undefined)
      return false;
    return !!(value+"");
  }
  validate.email=function(value) {
    value=value+"";
    return !!(value.match(/^.+@.+\..+$/));
  }
  
  $("form[data-validate]").each(function() {
    $(this).on("submit", function(e) {
      var valid=true;
      $(this).find("input, select, textarea").each(function() {
        var reqs=[];
        var errors=[];
        for(var i in this.attributes) {
          var attr=this.attributes[i];
          if (attr.name && attr.name.indexOf("data-req-")==0)
            reqs.push({type: attr.name.substring(9), msg: attr.value});
        }
        console.log(this, reqs);
//         var reqs=($(this).attr("data-required") || "").split(" ");
        var validField=validate(reqs, $(this).val());
        if ($(this).attr("type")=="checkbox")
          validField={valid: $(this).prop("checked"), msg: $(this).attr("data-req-required")};
        if (!validField.valid) {
          if (valid) {
            valid=false;
            $(this).focus();
          }
          $(this).closest(".form-row").addClass("error");
          $(this).closest(".form-row").find(".invalid").html(validField.msg);
        } else {
          $(this).closest(".form-row").removeClass("error");
        }
      }).one("change", function(e) {
        $(this).closest(".form-row").removeClass("error");
      });
      if (!valid)
        e.preventDefault();
    });
  });
});

jQuery(function($) {
  $(".select:not(.select-custom)").each(function() {
    // ako želite da svi select box-evi budu custom stavite true ispod
    if (false) {
      $(this).addClass("select-custom");
      var ul=$("<ul />").appendTo($(this));
      $(this).find("select>option").each(function() {
        var li=$("<li />", {html: $(this).html(), "data-value": $(this).attr("value")}).appendTo(ul);
      });
      $(this).find("select").remove();
    } else {
      var el=$(this);
      var span=$(this).find(">span");
      el.find(">select").on("change", function() {
        var value=$(this).val();
        el.find(">select>option").each(function() {
          if ($(this).attr("value")==value)
            value=$(this).html().trim();
        });
        span.html(value);
      }).trigger("change");
    }
  });
  
  $(".select-custom").each(function() {
    var el=$(this);
    var span=$(this).find(">span");
    var lis=$(this).find("li");
    var currentIndex=0;
    span.click(function(e) {
      e.stopPropagation();
      el.toggleClass("open");
      lis.removeClass("selected").eq(currentIndex).addClass("selected");
    });
    $(document).click(function(e) {
      el.removeClass("open");
    });
    lis.click(function(e) {
      el.find("input").val($(this).attr("data-value") || $(this).html());
      span.html($(this).html());
      currentIndex=lis.index(this);
    }).mouseover(function(e) {
      lis.removeClass("selected");
      $(this).addClass("selected");
    }).eq(0).click();
  });
  
  $("input[type=file]").each(function() {
    if ($(this).closest(".attach-box").length) {
      var el=$(this);
      var max=el.attr("data-maxcount")*1 || 100000;
      var fieldName=el.attr("name");
      el.removeAttr("name");
      var fileList=$(this).closest(".ic").find(">ul");
      var link=$(this).closest(".ic").find(">a");
      link.click(function(e) { e.preventDefault(); });
      var onChange=function() {
        var fileName=($(this).val() || "").replace(/^.*\\/, "");
        var li=$("<li />", {html: fileName}).appendTo(fileList);
        var remove=$("<a />", {"class": "remove", html: "&times;", href: ""}).appendTo(li).click(function(e) {
          e.preventDefault();
          $(this).parent().remove();
          link.removeAttr("disabled");
        });
        var attrs={};
        for(var i in this.attributes) {
          if (!this.attributes[i].name)
            continue;
          if (this.attributes[i].name.indexOf("data-req-")==-1)
            attrs[this.attributes[i].name]=this.attributes[i].value;
        }
        console.log(attrs);
        var newFile=$("<input />", attrs).change(onChange);
        link.after(newFile);
        $(this).attr("name", fieldName).appendTo(li);
        if (fileList.find(">li").length>=max)
          link.attr("disabled", "disabled");
      }
      el.change(onChange);
    } else {
      var el=$(this);
      var container=el.parent();
      var label=container.find(">span");
      var button=container.find(">a");
      el.change(function() {
        var fileName=el.val() || "";
        label.html(fileName.replace(/^.*\\/, ""));
      });
    }
  });
  $(".show-pw").click(function(e) {
    e.preventDefault();
    var el=$(this);
    var input=el.parent().find("input[type=password],input[type=text]");
    if (input.attr("type")=="password") {
      input.attr("type", "text");
      if (el.attr("data-hide-text"))
        el.html(el.attr("data-hide-text"));
    } else {
      input.attr("type", "password");
      if (el.attr("data-show-text"))
        el.html(el.attr("data-show-text"));
    }
    input.focus();
  });
  $("form.gen-form").each(function() {
    var passwordStrength=$(this).find(".password-strength");
    var passwordField=$(this).find("input[type=password]").eq(0);
    if (passwordStrength.length==0)
      return;
    var calculateStrength=function(value) {
      if (true) { // use zxcvbn
        value=value || "";
        if (!value)
          return -1;
        var strength=zxcvbn(value).score;
        return Math.max(0, strength-1);
      } else {
        value=value || "";
        if (!value)
          return -1;
        if (value.length<8)
          return 0;
        // special char after letter or number is treated as 2 letters
        value=value.replace(/[a-zA-Z0-9][^a-zA-Z0-9]/g, "    ");
        // number after character is treated as 3 letters
        value=value.replace(/[0-9][a-zA-Z]/g, "   ");
        value=value.replace(/[a-zA-Z][0-9]/g, "   ");
        // capital letter after small letter is treated as 3 letters
        value=value.replace(/[a-z][A-Z]/g, "   ");
        value=value.replace(/[A-Z][a-z]/g, "   ");
        if (value.length<13)
          return 1;
        if (value.length<16)
          return 2;
        return 3;
      }
    }
    passwordField.on("input", function() {
      var strength=calculateStrength($(this).val());
      passwordStrength.find("li").each(function(index) {
        if (strength>=index)
          $(this).addClass("active");
        else
          $(this).removeClass("active");
      });
      passwordStrength.find("span").html(passwordStrength.find("span").attr("data-lvl"+strength) || "");
    });
  });
  
  $("[data-maxlength]").each(function() {
    var onChange=function(e) {
      var maxLength=$(this).attr("data-maxlength");
      if ($(this).val().length>maxLength) {
        var oldSelection=[this.selectionStart, this.selectionEnd];
        $(this).val($(this).val().substring(0, maxLength));
        this.selectionStart=oldSelection[0];
        this.selectionEnd=oldSelection[1];
      }
      if (e.type=="keypress" && $(this).val().length==maxLength && e.originalEvent.key.length==1 && this.selectionStart==maxLength)
        e.preventDefault();
    };
    $(this).change(onChange).keypress(onChange).keyup(onChange);
  });
});

